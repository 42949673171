import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const administrativeRoutes: RouteType[] = [
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.administrative.correspondence.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/correspondence/administrativeCorrespondence'
        )
    )
  },

  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.administrative.correspondence.received.create
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/correspondence/received/create/correspondenceReceivedCreate'
        )
    )
  },
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: `${urlRoutes.management.administrative.correspondence.received.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/correspondence/received/edit/correspondenceReceivedEdit'
        )
    )
  },

  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.administrative.correspondence.sent.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/correspondence/sent/create/correspondenceSentCreate'
        )
    )
  },
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: `${urlRoutes.management.administrative.correspondence.sent.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/correspondence/sent/edit/correspondenceSentEdit'
        )
    )
  },

  {
    permissions: [
      'combustible.create.permission',
      'combustible.edit.permission',
      'combustible.delete.permission',
      'combustible.read.permission'
    ],
    path: urlRoutes.management.administrative.fuel.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/fuel/list/administrativeFuelList'
        )
    )
  },
  {
    permissions: [
      'combustible.create.permission',
      'combustible.edit.permission',
      'combustible.delete.permission',
      'combustible.read.permission'
    ],
    path: urlRoutes.management.administrative.fuel.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/fuel/create/administrativeFuelCreate'
        )
    )
  },
  {
    permissions: [
      'combustible.create.permission',
      'combustible.edit.permission',
      'combustible.delete.permission',
      'combustible.read.permission'
    ],
    path: `${urlRoutes.management.administrative.fuel.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/fuel/edit/administrativeFuelEdit'
        )
    )
  },

  {
    permissions: [
      'registro.create.permission',
      'registro.edit.permission',
      'registro.delete.permission',
      'registro.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.register.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/register/list/employeeRegisterList'
        )
    )
  },
  {
    permissions: [
      'registro.create.permission',
      'registro.edit.permission',
      'registro.delete.permission',
      'registro.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.register.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/register/create/employeeRegisterCreate'
        )
    )
  },
  {
    permissions: [
      'registro.create.permission',
      'registro.edit.permission',
      'registro.delete.permission',
      'registro.read.permission'
    ],
    path: `${urlRoutes.management.administrative.employee.register.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/register/edit/employeeRegisterEdit'
        )
    )
  },

  {
    permissions: [
      'control_de_traslado.create.permission',
      'control_de_traslado.edit.permission',
      'control_de_traslado.delete.permission',
      'control_de_traslado.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.transfer.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/transfer/list/employeeTransferList'
        )
    )
  },
  {
    permissions: [
      'control_de_traslado.create.permission',
      'control_de_traslado.edit.permission',
      'control_de_traslado.delete.permission',
      'control_de_traslado.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.transfer.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/transfer/create/employeeTransferCreate'
        )
    )
  },
  {
    permissions: [
      'control_de_traslado.create.permission',
      'control_de_traslado.edit.permission',
      'control_de_traslado.delete.permission',
      'control_de_traslado.read.permission'
    ],
    path: `${urlRoutes.management.administrative.employee.transfer.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/transfer/edit/employeeTransferEdit'
        )
    )
  },

  {
    permissions: [
      'control_de_renuncia.create.permission',
      'control_de_renuncia.edit.permission',
      'control_de_renuncia.delete.permission',
      'control_de_renuncia.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.resignation.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/resignation/list/employeeResignationList'
        )
    )
  },
  {
    permissions: [
      'control_de_renuncia.create.permission',
      'control_de_renuncia.edit.permission',
      'control_de_renuncia.delete.permission',
      'control_de_renuncia.read.permission'
    ],
    path: urlRoutes.management.administrative.employee.resignation.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/resignation/create/employeeResignationCreate'
        )
    )
  },
  {
    permissions: [
      'control_de_renuncia.create.permission',
      'control_de_renuncia.edit.permission',
      'control_de_renuncia.delete.permission',
      'control_de_renuncia.read.permission'
    ],
    path: `${urlRoutes.management.administrative.employee.resignation.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/employee/resignation/edit/employeeResignationEdit'
        )
    )
  },

  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/settingsFixedAssets'
        )
    )
  },

  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.active.add
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/active/create/fixedAssetsActiveCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.active.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/active/edit/fixedAssetsActiveEdit'
        )
    )
  },

  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.brand.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/brand/create/fixedAssetsBrandCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.brand.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/brand/edit/fixedAssetsBrandEdit'
        )
    )
  },

  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.model.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/model/create/fixedAssetsModelCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.model.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/model/edit/fixedAssetsModelEdit'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.color.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/color/create/fixedAssetsColorCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.color.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/color/edit/fixedAssetsColorEdit'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.location.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/location/create/fixedAssetsLocationCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.location.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/location/edit/fixedAssetsLocationEdit'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.status
      .add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/statusActive/create/fixedAssetsStatusActiveCreate'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedassets.status.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/statusActive/edit/fixedAssetsStatusActiveEdit'
        )
    )
  },
  {
    permissions: [
      'activos_fijos.create.permission',
      'activos_fijos.edit.permission',
      'activos_fijos.delete.permission',
      'activos_fijos.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedassets.status
      .add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/statusActive/create/fixedAssetsStatusActiveCreate'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.management.administrative.setting.fixedassets.typology
      .add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/typology/create/fixedAssetsTypologyCreate'
        )
    )
  },
  {
    permissions: [],
    path: `${urlRoutes.management.administrative.setting.fixedassets.typology.add.url}/:id`,

    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/typology/edit/fixedAssetsTypologyEdit'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.management.administrative.setting.fixedassets.nature
      .add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/nature/create/fixedAssetsNatureCreate'
        )
    )
  },
  {
    permissions: [],
    path: `${urlRoutes.management.administrative.setting.fixedassets.nature.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedassets/nature/edit/fixedAssetsNatureEdit'
        )
    )
  },

  // Creacción activos fijos
  {
    permissions: [
      'creacion_activo_fijo.create.permission',
      'creacion_activo_fijo.edit.permission',
      'creacion_activo_fijo.delete.permission',
      'creacion_activo_fijo.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedAssetsRegistry.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedAssetsRegistry/list/fixedAssetsRegistryList'
        )
    )
  },
  {
    permissions: [
      'creacion_activo_fijo.create.permission',
      'creacion_activo_fijo.edit.permission',
      'creacion_activo_fijo.delete.permission',
      'creacion_activo_fijo.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.fixedAssetsRegistry.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedAssetsRegistry/create/fixedAssetsRegistryCreate'
        )
    )
  },
  {
    permissions: [
      'creacion_activo_fijo.create.permission',
      'creacion_activo_fijo.edit.permission',
      'creacion_activo_fijo.delete.permission',
      'creacion_activo_fijo.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.fixedAssetsRegistry.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/fixedAssetsRegistry/edit/fixedAssetsRegistryEdit'
        )
    )
  },

  {
    permissions: [
      'viaticos_y_viajes.create.permission',
      'viaticos_y_viajes.edit.permission',
      'viaticos_y_viajes.delete.permission',
      'viaticos_y_viajes.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.perDiemAndTravel.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/perDiemAndTravel/list/settingsPerDiemTravelList'
        )
    )
  },
  {
    permissions: [
      'viaticos_y_viajes.create.permission',
      'viaticos_y_viajes.edit.permission',
      'viaticos_y_viajes.delete.permission',
      'viaticos_y_viajes.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.perDiemAndTravel.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/perDiemAndTravel/create/settingsPerDiemTravelCreate'
        )
    )
  },
  {
    permissions: [
      'viaticos_y_viajes.create.permission',
      'viaticos_y_viajes.edit.permission',
      'viaticos_y_viajes.delete.permission',
      'viaticos_y_viajes.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.perDiemAndTravel.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/perDiemAndTravel/edit/settingsPerDiemTravelEdit'
        )
    )
  },

  {
    permissions: [
      'materiales_y_libros.create.permission',
      'materiales_y_libros.edit.permission',
      'materiales_y_libros.delete.permission',
      'materiales_y_libros.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.materialsBooks.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/materialsBooks/settingsMateriaBooks'
        )
    )
  },

  {
    permissions: [
      'materiales_y_libros.create.permission',
      'materiales_y_libros.edit.permission',
      'materiales_y_libros.delete.permission',
      'materiales_y_libros.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.materialsBooks.article.add
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/materialsBooks/articles/create/materiaBooksArticlesCreate'
        )
    )
  },
  {
    permissions: [
      'materiales_y_libros.create.permission',
      'materiales_y_libros.edit.permission',
      'materiales_y_libros.delete.permission',
      'materiales_y_libros.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.materialsBooks.article.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/materialsBooks/articles/edit/materiaBooksArticlesEdit'
        )
    )
  },

  {
    permissions: [
      'carreras.create.permission',
      'carreras.edit.permission',
      'carreras.delete.permission',
      'carreras.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.careers.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/careers/list/settingsCareersList'
        )
    )
  },
  {
    permissions: [
      'carreras.create.permission',
      'carreras.edit.permission',
      'carreras.delete.permission',
      'carreras.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.careers.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/careers/create/settingsCareersCreate'
        )
    )
  },
  {
    permissions: [
      'carreras.create.permission',
      'carreras.edit.permission',
      'carreras.delete.permission',
      'carreras.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.careers.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/careers/edit/settingsCareersEdit'
        )
    )
  },

  {
    permissions: [
      'universidades.create.permission',
      'universidades.edit.permission',
      'universidades.delete.permission',
      'universidades.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.universities.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/universities/list/settingUniversitiesList'
        )
    )
  },
  {
    permissions: [
      'universidades.create.permission',
      'universidades.edit.permission',
      'universidades.delete.permission',
      'universidades.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.universities.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/universities/create/settingsUniversitiesCreate'
        )
    )
  },
  {
    permissions: [
      'universidades.create.permission',
      'universidades.edit.permission',
      'universidades.delete.permission',
      'universidades.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.universities.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/universities/edit/settingsUniversitiesEdit'
        )
    )
  },

  {
    permissions: [
      'almacen.create.permission',
      'almacen.edit.permission',
      'almacen.delete.permission',
      'almacen.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks.store
      .list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/store/list/storeList'
        )
    )
  },
  {
    permissions: [
      'almacen.create.permission',
      'almacen.edit.permission',
      'almacen.delete.permission',
      'almacen.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks.store
      .create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/store/create/storeCreate'
        )
    )
  },
  {
    permissions: [
      'almacen.create.permission',
      'almacen.edit.permission',
      'almacen.delete.permission',
      'almacen.read.permission'
    ],
    path: `${urlRoutes.management.administrative.registrationControlBooks.store.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/store/edit/storeEdit'
        )
    )
  },

  {
    permissions: [
      'consulta_almacen.create.permission',
      'consulta_almacen.edit.permission',
      'consulta_almacen.delete.permission',
      'consulta_almacen.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks
      .booksInStore.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/booksInStore/list/booksStoreList'
        )
    )
  },

  {
    permissions: [
      'agregar_libros_al_almacen.create.permission',
      'agregar_libros_al_almacen.edit.permission',
      'agregar_libros_al_almacen.delete.permission',
      'agregar_libros_al_almacen.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks
      .addBookToStore.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/addBookToStore/list/booksToStoreList'
        )
    )
  },
  {
    permissions: [
      'agregar_libros_al_almacen.create.permission',
      'agregar_libros_al_almacen.edit.permission',
      'agregar_libros_al_almacen.delete.permission',
      'agregar_libros_al_almacen.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks
      .addBookToStore.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/addBookToStore/create/booksToStoreCreate'
        )
    )
  },
  {
    permissions: [
      'agregar_libros_al_almacen.create.permission',
      'agregar_libros_al_almacen.edit.permission',
      'agregar_libros_al_almacen.delete.permission',
      'agregar_libros_al_almacen.read.permission'
    ],
    path: `${urlRoutes.management.administrative.registrationControlBooks.addBookToStore.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/addBookToStore/edit/booksToStoreEdit'
        )
    )
  },

  {
    permissions: [
      'consulta_de_solicitud.create.permission',
      'consulta_de_solicitud.edit.permission',
      'consulta_de_solicitud.delete.permission',
      'consulta_de_solicitud.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks
      .requestBook.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/requestBook/list/requestBookList'
        )
    )
  },
  {
    permissions: [
      'consulta_de_solicitud.create.permission',
      'consulta_de_solicitud.edit.permission',
      'consulta_de_solicitud.delete.permission',
      'consulta_de_solicitud.read.permission'
    ],
    path: urlRoutes.management.administrative.registrationControlBooks
      .requestBook.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/requestBook/create/requestBookCreate'
        )
    )
  },

  {
    permissions: [
      'consulta_de_solicitud.create.permission',
      'consulta_de_solicitud.edit.permission',
      'consulta_de_solicitud.delete.permission',
      'consulta_de_solicitud.read.permission'
    ],
    path: `${urlRoutes.management.administrative.registrationControlBooks.requestBook.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/registrationControlBooks/requestBook/edit/requestBookEdit'
        )
    )
  },

  {
    permissions: [
      'mantenimiento.create.permission',
      'mantenimiento.edit.permission',
      'mantenimiento.delete.permission',
      'mantenimiento.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.maintenance.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/maintenance/list/settingsMaintenanceList'
        )
    )
  },
  {
    permissions: [
      'mantenimiento.create.permission',
      'mantenimiento.edit.permission',
      'mantenimiento.delete.permission',
      'mantenimiento.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.maintenance.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/maintenance/create/settingsMaintenanceCreate'
        )
    )
  },
  {
    permissions: [
      'mantenimiento.create.permission',
      'mantenimiento.edit.permission',
      'mantenimiento.delete.permission',
      'mantenimiento.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.maintenance.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/maintenance/edit/settingsMaintenanceEdit'
        )
    )
  },

  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.correspondence.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/correspondence/list/settingsCorrespondenceList'
        )
    )
  },
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.correspondence.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/correspondence/create/settingsCorrespondenceCreate'
        )
    )
  },
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.correspondence.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/correspondence/edit/settingsCorrespondenceEdit'
        )
    )
  },

  {
    permissions: [
      'posicion.create.permission',
      'posicion.edit.permission',
      'posicion.delete.permission',
      'posicion.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.position.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/position/list/settingsPositionCreate'
        )
    )
  },
  {
    permissions: [
      'posicion.create.permission',
      'posicion.edit.permission',
      'posicion.delete.permission',
      'posicion.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.position.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/position/create/settingsPositionCreate'
        )
    )
  },
  {
    permissions: [
      'posicion.create.permission',
      'posicion.edit.permission',
      'posicion.delete.permission',
      'posicion.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.position.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/position/edit/settingsPositionEdit'
        )
    )
  },

  {
    permissions: [
      'registro_de_libros.create.permission',
      'registro_de_libros.edit.permission',
      'registro_de_libros.delete.permission',
      'registro_de_libros.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.bookRecord.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/bookRecord/settingsBookRecord'
        )
    )
  },

  {
    permissions: [
      'registro_de_libros.create.permission',
      'registro_de_libros.edit.permission',
      'registro_de_libros.delete.permission',
      'registro_de_libros.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.bookRecord.book.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/bookRecord/book/create/bookRecordBookCreate'
        )
    )
  },
  {
    permissions: [
      'registro_de_libros.create.permission',
      'registro_de_libros.edit.permission',
      'registro_de_libros.delete.permission',
      'registro_de_libros.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.bookRecord.book.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/bookRecord/book/edit/bookRecordBookEdit'
        )
    )
  },

  {
    permissions: [
      'registro_de_libros.create.permission',
      'registro_de_libros.edit.permission',
      'registro_de_libros.delete.permission',
      'registro_de_libros.read.permission'
    ],
    path: urlRoutes.management.administrative.setting.bookRecord.typology.add
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/bookRecord/typology/create/bookRecordTypoCreate'
        )
    )
  },
  {
    permissions: [
      'registro_de_libros.create.permission',
      'registro_de_libros.edit.permission',
      'registro_de_libros.delete.permission',
      'registro_de_libros.read.permission'
    ],
    path: `${urlRoutes.management.administrative.setting.bookRecord.typology.add.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/administrative/setting/bookRecord/typology/edit/bookRecordTypoEdit'
        )
    )
  },

  {
    permissions: [
      'caja_chica.create.permission',
      'caja_chica.edit.permission',
      'caja_chica.delete.permission',
      'caja_chica.read.permission'
    ],
    path: urlRoutes.management.cash.url,
    element: lazy(
      async () => await import('ui/modules/management/cash/managementCash')
    )
  },

  {
    permissions: [
      'caja_chica.create.permission',
      'caja_chica.edit.permission',
      'caja_chica.delete.permission',
      'caja_chica.read.permission'
    ],
    path: urlRoutes.management.cash.expenses.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/cash/expenses/list/cashExpensesList'
        )
    )
  },
  {
    permissions: [
      'caja_chica.create.permission',
      'caja_chica.edit.permission',
      'caja_chica.delete.permission',
      'caja_chica.read.permission'
    ],
    path: urlRoutes.management.cash.expenses.add.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/cash/expenses/add/cashExpensesAdd')
    )
  },

  {
    permissions: [
      'caja_chica.create.permission',
      'caja_chica.edit.permission',
      'caja_chica.delete.permission',
      'caja_chica.read.permission'
    ],
    path: urlRoutes.management.cash.incomes.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/cash/incomes/list/cashIncomesList')
    )
  },
  {
    permissions: [
      'caja_chica.create.permission',
      'caja_chica.edit.permission',
      'caja_chica.delete.permission',
      'caja_chica.read.permission'
    ],
    path: urlRoutes.management.cash.incomes.add.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/cash/incomes/add/cashIncomesAdd')
    )
  },

  {
    permissions: [
      'facturas.create.permission',
      'facturas.edit.permission',
      'facturas.delete.permission',
      'facturas.read.permission'
    ],
    path: urlRoutes.management.invoices.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/invoices/list/invoicesList')
    )
  },
  {
    permissions: [
      'facturas.create.permission',
      'facturas.edit.permission',
      'facturas.delete.permission',
      'facturas.read.permission'
    ],
    path: urlRoutes.management.invoices.add.url,
    element: lazy(
      async () => await import('ui/modules/management/invoices/add/invoicesAdd')
    )
  },
  {
    permissions: [
      'facturas.create.permission',
      'facturas.edit.permission',
      'facturas.delete.permission',
      'facturas.read.permission'
    ],
    path: urlRoutes.management.invoices.detail.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/invoices/detail/invoicesDetail')
    )
  },

  {
    permissions: [
      'contratos.create.permission',
      'contratos.edit.permission',
      'contratos.delete.permission',
      'contratos.read.permission'
    ],
    path: urlRoutes.management.contracts.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/contracts/list/contractsList')
    )
  },
  {
    permissions: [
      'contratos.create.permission',
      'contratos.edit.permission',
      'contratos.delete.permission',
      'contratos.read.permission'
    ],
    path: urlRoutes.management.contracts.add.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/contracts/add/contractsAdd')
    )
  },
  {
    permissions: [
      'contratos.create.permission',
      'contratos.edit.permission',
      'contratos.delete.permission',
      'contratos.read.permission'
    ],
    path: urlRoutes.management.contracts.detail.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/contracts/detail/contractsDetail')
    )
  },
  {
    permissions: [
      'carta_constancia.create.permission',
      'carta_constancia.edit.permission',
      'carta_constancia.delete.permission',
      'carta_constancia.read.permission'
    ],
    path: urlRoutes.management.proofStudyLetter.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/administrative/proofStudyLetter/proofStudyLetter')
    )
  },
  {
    permissions: [
      'certificado.create.permission',
      'certificado.edit.permission',
      'certificado.delete.permission',
      'certificado.read.permission'
    ],
    path: urlRoutes.management.certificate.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/administrative/certificate/administrativeCertificate')
    )
  },
  {
    permissions: [
      'certificado.create.permission',
      'certificado.edit.permission',
      'certificado.delete.permission',
      'certificado.read.permission'
    ],
    path: urlRoutes.management.certificate.add.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/administrative/certificate/create/createCertificate')
    )
  },
  {
    permissions: [
      'certificado.create.permission',
      'certificado.edit.permission',
      'certificado.delete.permission',
      'certificado.read.permission'
    ],
    path: urlRoutes.management.certificate.edit.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/administrative/certificate/edit/editCertificate')
    )
  }
]
