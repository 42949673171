import type { NotificationRequest } from "core/notification/domain/request/notification-request.type";

export const endPoints = {
  auth: {
    login: "Account/Login",
    userExist: (email: string) => `Account/UsedExist?email=${email}`,
    register: "Account/RegisterStudient",
    verifyIdentity: "Account/VerifyIdentity",
    recover: "Account/RecoverPassword",
    changePassword: "Account/ChangePassword",
    resetPassword: "Account/ResetPassword",
    resendEmail: "Account/ResendConfirmationEmail",
  },
  language: {
    get: ({ query = "" }) => `Language${query && `?${query}`}`,
    getLanguageByAnnouncement: (announcementId: string) =>
      `Language/GetLanguageByAnnouncement?announcementId=${announcementId}`,
    show: (id: string | undefined) => `Language/GetLanguages/${id}`,
    post: "Language/PostLanguage",
    put: (id: string | undefined) => `Language/UpdateLanguage/${id}`,
    delete: (id: string) => `Language/Delete?id=${id}`,
  },
  transferReason: {
    get: ({ query = "" }) => `TransferReasons/Get${query && `?${query}`}`,
    post: "TransferReasons/PostTransferReasons",
    show: (id: string | undefined) =>
      `TransferReasons/GetTransferReasons/${id}`,
    put: `TransferReasons/UpdateTransferReasons`,
    delete: (id: string) => `TransferReasons/Delete?id=${id}`,
  },

  student: {
    savePersonalData: (email: string) => `Student/PutUpdateApplican/${email}`,
    saveAcademicData: "Student/PostAcademyApplicant",
    saveWorkData: "Student/PostApplicantWork",
    uploadDocuments: "Student/PostFile",
    announcement: (id: string) =>
      `Student/GetAnnouncementAvailable?applicantId=${id}`,
  },
  announcement: {
    get: ({ query = "" }) => `Announcement/Get${query && `?${query}`}`,
    getByUserLogged: `Announcement/by-profiles`,
    getTechnical: `Announcement/GetAnouncementAssignedTechnical/technical`,
    getWithParams: (page?: string, cant?: number, name?: string) => {
      let url = "Announcement/Get";
      if (page || cant || name) {
        url += "?";
        if (name) {
          url += `&Name=${name}`;
        }
        if (page) {
          url += `&Pagina=${page}`;
        }
        if (cant) {
          url += `&CantidadRegistrosPorPagina=${cant}`;
        }
      }

      return url;
    },
    show: (id: string | undefined) => `Announcement/GetAnnouncement/${id}`,
    post: "Announcement/PostAnnouncement",
    put: (id: string | undefined) => `Announcement/UpdateAnnouncement/${id}`,
    setStatus: (idAnmouncement: any, id: any) =>
      `Announcement/SetStatus?idAnmouncement=${idAnmouncement}&idState=${id}`,
    getByStatus: ({ query = "" }) =>
      `Announcement/GetByStatus/GetByStatus${query && `?${query}`}`,
    getRequests: (status: number) =>
      `AnnouncementRequest/GetByStatus/?status=${status}`,
    setRequestStatus: (id: string | undefined) =>
      `AnnouncementRequest/SetAnnouncementRequestStatus/${id}`,
    getRequestById: (id: string | undefined) =>
      `AnnouncementRequest/GetAnnouncementRequest/${id}`,
    getRequestsFilter: (filter?: any) =>
      `AnnouncementRequest/GetAnnouncementRequests?AnnoucementId=${filter.annoucementId}`,
    createAnnoucementRequest: "AnnouncementRequest/Create",
    setAnnouncementRequestStatus: (path: string, id: string) =>
      `AnnouncementRequest/${path}?idAnnouncementRequest=${id}`,
    getProfile: (id: string) => `Student/GetProfile?applicantId=${id}`,
    delete: (id: string) => `Announcement/Delete/${id}`,
    getAnnouncementDependence: (id: string) =>
      `AnnouncementDependence/GetAnnouncementDependences?announcementId=${id}`,
    getAnnouncementsDependences: (query = "") =>
      `AnnouncementDependence/GetDependences${query}`,
  },
  graduateStatus: {
    getGraduateStatusStudent: (query: string) =>
      `GraduateStatus/GraduateStatusStudent${query && `?${query}`}`,
    postGraduateStatus: "GraduateStatus/PostGraduateStatus",
    postGraduateStatusStudent: "GraduateStatus/PostGraduateStatusStudent",
  },
  province: {
    get: ({ query = "" }) => `Province${query && `?${query}`}`,
    getProvinceByIdAnnouncement: (id: string | undefined) => `AnnouncementProvince?anouncementId=${id}`,
    getProvinceByIdAnnouncementTeacher: (id: string | undefined) => `AnnouncementTeacher/Province?announcementId=${id}`,
    getProvinceByIdAnnouncementAndIdRegion: (anouncementId: string | undefined, regionId: string | undefined) => 
      `AnnouncementProvince/GetByAnnoucementIdAndRegionId?anouncementId=${anouncementId}&regionId=${regionId}`,
  },
  institution: {
    get: ({ query = "" }) => `Institution${query && `?${query}`}`,
    getByUserLoggedAll:
      `/Institution/by-profiles`,
    getByUserLogged: (idAnnouncement = "") =>
      `/Institution/by-profiles?anouncementId=${idAnnouncement}`,
    getTechnical: (idAnnouncement: string) =>
      `Institution/anouncement/${idAnnouncement}/technical`,
    show: (id: string | undefined) => `Institution/GetInstitution/${id}`,
    post: "Institution/PostInstitution",
    getByProvinces: "Institution/InstitutionsProvinces",
    put: `Institution/UpdateInstitution`,
    delete: (id: string) => `Institution/Delete?id=${id}`,
  },
  enclosure: {
    get: ({ query = "" }) => `Dependence/Get${query && `?${query}`}`,
    getByTeacher: ({ query = "" }) =>
      `Dependence/teachers${query && `?${query}`}`,
    getByUserLogged: ({ query = "" }) => `Dependence/by-profiles${query && `?${query}`}`,
    getTechnical: ({ announcementId, institutionId }: any) =>
      `Dependence/anouncement/${announcementId}/institution/${institutionId}/technical`,
    getStudent: ({ query = "" }) =>
      `Dependence/Students${query && `?${query}`}`,
    show: (id: string | undefined) => `Dependence/GetById/${id}`,
    post: "Dependence/Post",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    put: (id: string | undefined) => `Dependence/Update`,
    getIdInstitution: (id: string | undefined) =>
      `Dependence/GetAllByIntitution?institutionId=${id}`,
    getIdInstitutionAdmin: (id: string | undefined) =>
      `Dependence/GetAllByIntitution?institutionId=${id}&isModalityAdmin=true`,
    delete: (id: string) => `Dependence/Delete?id=${id}`,
    DependencesProvinces: ({ query = "" }) => `Dependence/GetDependencesProvinces${query && `?${query}`}`,
  },
  evaluation: {
    get: ({ query = "" }) => `Evaluation${query && `?${query}`}`,
    show: (id: string | undefined) => `Evaluation/GetEvaluation/${id}`,
    post: "Evaluation/PostEvaluation",
    put: `Evaluation/UpdateEvaluation`,
    putAll: `Evaluation/UpdateEvaluationAll`,
    delete: (id: string) => `Evaluation/Delete?id=${id}`,

    showByIdAnnouncement: (id: string | undefined) =>
      `Evaluation/GetByAnnouncement/${id}`,
    showByIdInstitution: (id: string | undefined) =>
      `Evaluation/GetByIntitution/${id}`,
    showByIdDependence: (id: string | undefined) =>
      `Evaluation/GetByDependence/${id}`,
    showByIdLevel: (id: string | undefined) => `Evaluation/GetByLevel/${id}`,
  },
  itemsEvaluationTeacher: {
    get: ({ query = "" }) => `EvaluationTeacher${query && `?${query}`}`,
    show: (id: string | undefined) => `EvaluationTeacher/GetEvaluationTeacher/${id}`,
    post: 'EvaluationTeacher/PostEvaluation',
    put: 'EvaluationTeacher/UpdateEvaluation',
    delete: (id: string) => `EvaluationTeacher/Delete?id=${id}`,
  },
  level: {
    get: ({ query = "" }) => `Level/GetAllLevel${query && `?${query}`}`,
    getLevelId: (id: string) => `Level/GetLevel?Id=${id}`,
    show: (id: string | undefined) => `Level/GetLevelBy?id=${id}`,
    post: "Level/PostLevel",
    put: `Level/UpdateLevel`,
    putAllLevel: `Level/UpdateLevelAll`,
    delete: (id: string) => `Level/Delete?id=${id}`,

    start: (idState: string | undefined, id: string | undefined) =>
      `Level/GetLevelState?IdState=${idState}&IdLevel=${id}`,

    showByIdAnnouncement: (
      idAnnouncement: string | undefined,
      dependenceId: string | undefined
    ) =>
      `Level/GetLevelByAnnouncement?idAnnouncement=${idAnnouncement}&dependenceId=${dependenceId}`,
    showByIdAnnouncementAndDependence: (
      idAnnouncement: string | undefined,
      idDependence: string | undefined
    ) =>
      `Level/GetLevelByDependencesAnnouncement?idAnnouncement=${idAnnouncement}&idDependence=${idDependence}`,
    showByAnnouncement: (
      idAnnouncement: string | undefined,
    ) =>
      `Level/GetLevelByAnnouncement?idAnnouncement=${idAnnouncement}`,
    showByIdDependence: (id: string | undefined) =>
      `Level/GetLevelByDependence?idDependence=${id}`,
    showByIdInstitution: (id: string | undefined) =>
      `Level/GetLevelByInstitution?idInstitution=${id}`,
  },
  levelLanguage: {
    get: `LevelLanguage/Get`,
    getBy: ({ query = "" }) => `LevelLanguage/Get${query && `?${query}`}`,
    getById: (query: any) => `LevelLanguage/Get/${query}`,
    getLevelLanguageByLanguageId: (idLanguage: string) =>
      `LevelLanguage/GetLevelLanguage/${idLanguage}`,
    getLevelByAnnouncementId: (id: string) =>
      `LevelLanguage/GetLevelAnnouncement/${id}`,
    show: (id: string | undefined) => `LevelLanguage/${id}`,
    post: `LevelLanguage/Post`,
    put: `LevelLanguage/Update`,
    delete: (id: string) => `LevelLanguage/Delete?id=${id}`,
  },
  lenguage: {
    get: "Language",
  },
  modality: {
    get: ({ query = "" }) => `Modality/GetModality${query && `?${query}`}`,
    show: (id: string | undefined) => `Modality/GetModality/${id}`,
    post: "Modality/PostModality",
    put: (id: string | undefined) => `Modality/UpdateModality/${id}`,
    delete: (id: string) => `Modality/Delete?id=${id}`,
  },
  quota: {
    get: ({ query = "" }) =>
      `SelectionAvailable/GetSelections${query && `?${query}`}`,
    showByIdAnnouncement: (id: string | undefined) =>
      `SelectionAvailable/GetByAnnoncement?idAnnoncement=${id}`,
    showByIdModality: (id: string | undefined) =>
      `SelectionAvailable/GetByModality?idModality=${id}`,
    show: (id: string | undefined) => `SelectionAvailable/GetSelection/${id}`,
    post: "SelectionAvailable/Post",
    put: "SelectionAvailable/UpdateSelection",
    putState: "SelectionAvailable/UpdateState",
    delete: (id: string) => `SelectionAvailable/Delete?id=${id}`,
  },
  report: {
    get: ({ query = "" }) =>
      `Report/GetStatusAnnouncementReport${query && `?${query}`}`,
    getReportStudent: ({ query = "" }) =>
      `Report/GetStudentByDependenceReport${query && `?${query}`}`,
    getReportProofOfStudy: ({ query = "" }) =>
      `Report/GetProofOfStudy${query && `?${query}`}`,
  },
  classroom: {
    get: ({ query = "" }) => `Classroom/GetClassroom${query && `?${query}`}`,
    delete: ({ id = "" }) => `Classroom/Delete?id=${id}`,

    getPassList: ({ query = "" }: any) =>
      `Classroom/GetAttendanceStudentClassRoom${query && `?${query}`}`,
    putPassList: "Classroom/AttendanceStudentClassRoom",
    postPassList: "Classroom/AttendanceStudentClassRoom",

    getTardiesExcuses: ({ query = "" }: any) =>
      `Classroom/GetStudentClassExcusesDelay${query && `?${query}`}`,
    putTardiesExcuses: "Classroom/StudentClassExcusesDelay",
    postTardiesExcuses: "Classroom/AddStudentClassExcusesDelay",

    getPassListLevel: ({ query = "" }: any) =>
      `Classroom/GetAttendanceControlLevel${query && `?${query}`}`,
    putPassListLevel: "Classroom/PutAttendanceControlLevel",
    postPassListLevel: "Classroom/AddAttendanceControlLevel",

    getRegistrationTeacherAttendanceControl: ({ query = "" }: any) =>
      `Classroom/GetTeacherAssistanceDetail${query && `?${query}`}`,
    putRegistrationTeacherAttendanceControl: "Classroom/PutTeacherAssistance",
    postRegistrationTeacherAttendanceControl: "Classroom/AddTeacherAssistance",

    getByUserLogged: ({ announcementId, dependenceId }: any) =>
      `Classroom/by-profiles?anouncementId=${announcementId}&dependenceId=${dependenceId}`,
    getTechnical: ({ announcementId, dependenceId }: any) =>
      `Classroom/anouncement/${announcementId}/dependence/${dependenceId}/technical`,
    getStudent: ({ query = "" }) =>
      `Classroom/StudentInClassroom${query && `?${query}`}`,
    classroom: ({ query = "" }) =>
      `Classroom/Classrooms${query && `?${query}`}`,
    show: (id: string | undefined) => `Classroom/GetClassroom/${id}`,
    post: "Classroom/PostClassroom",
    put: (id: string | undefined) => `Classroom/UpdateClassroom/${id}`,
  },
  teacher: {
    get: ({ query = "" }) =>
      `Employees/GetEmployee?Rol=Profesor${query && `&${query}`}`,
  },
  coordinator: {
    get: ({ query = "" }) =>
      `Employees/GetEmployee?Rol=Coordinador${query && `&${query}`}`,
  },
  general: {
    getTandas: "Tandas",
  },
  state: {
    get: "Status",
  },
  reporting: {
    post: (reportName: string)=>`Reporting/${reportName}`,
  },
  pettyCash: {
    getExpenses: (filter: any) =>
      `PettyCash/GetExpenses?Concept=${filter.concept}&StartDate=${filter.startDate}&EndDate=${filter.finalDate}&Page=${filter.page}`,
    addExpense: "PettyCash/AddExpense",
    getIncomes: (filter: any) =>
      `PettyCash/GetIncomes?Concept=${filter.concept}&StartDate=${filter.startDate}&EndDate=${filter.finalDate}&Page=${filter.page}`,
    addIncome: "PettyCash/AddIncome",
    getBalance: `PettyCash/GetBalance`,
  },
  invoice: {
    get: (filter: any) =>
      `Invoice/GetAll?Name=${filter.concept}&StartDate=${filter.startDate}&EndDate=${filter.finalDate}&StartAmount=${filter.startAmount}&EndDate=${filter.endAmount}&Page=${filter.page}`,
    add: "Invoice/AddInvoice",
    uploadInvoice: "Invoice/Add",
  },
  contracts: {
    get: (filter: any) =>
      `Contract/GetContract?Search=${filter.concept}&FechaInicio=${filter.startDate}&FechaFin=${filter.finalDate}&Page=${filter.page}`,
    add: "Contract/PostContract",
  },
  batch: {
    get: "Tandas",
  },
  active: {
    get: (nameSearch: string) =>
      nameSearch ? `Active?Search=${nameSearch}` : "Active",
    show: (id: any) => `Active/GetActive/${id}`,
    post: "Active/PostActive",
    put: "Active/UpdateActive",
    delete: (id: string) => `Active/Delete?id=${id}`,
  },
  brand: {
    get: (nameSearch: string) =>
      nameSearch ? `Brand?Search=${nameSearch}` : "Brand",
    show: (id: any) => `Brand/GetBrand/${id}`,
    post: "Brand/PostBrand",
    put: "Brand/UpdateBrand",
    delete: (id: string) => `Brand/Delete?id=${id}`,
  },
  model: {
    get: (nameSearch: string) =>
      nameSearch ? `Model?Search=${nameSearch}` : "Model",
    show: (id: any) => `Model/GetModel/${id}`,
    post: "Model/PostModel",
    put: "Model/UpdateModel",
    delete: (id: string) => `Model/Delete?id=${id}`,
  },
  color: {
    get: (nameSearch: string) =>
      nameSearch ? `Color?Search=${nameSearch}` : "Color",
    show: (id: any) => `Color/GetColor/${id}`,
    post: "Color/PostColor",
    put: "Color/UpdateColor",
    delete: (id: string) => `Color/Delete?id=${id}`,
  },
  nature: {
    get: (nameSearch: string) =>
      nameSearch ? `Nature?Search=${nameSearch}` : "Nature",
    show: (id: any) => `Nature/GetNature/${id}`,
    post: "Nature/PostNature",
    put: "Nature/UpdateNature",
    delete: (id: string) => `Nature/Delete?id=${id}`,
  },
  typologyFixedAsset: {
    get: (nameSearch: string) =>
      nameSearch ? `TypologyFixedAsset?Search=${nameSearch}` : "TypologyFixedAsset",
    show: (id: any) => `TypologyFixedAsset/Get/${id}`,
    post: "TypologyFixedAsset/Post",
    put: "TypologyFixedAsset/Update",
    delete: (id: string) => `TypologyFixedAsset/Delete?id=${id}`,
  },
  location: {
    get: (nameSearch: string) =>
      nameSearch ? `Location?Search=${nameSearch}` : "Location",
    show: (id: any) => `Location/GetLocation/${id}`,
    post: "Location/PostLocation",
    put: "Location/Update",
    delete: (id: string) => `Location/Delete?id=${id}`,
  },
  assetCondition: {
    get: (nameSearch: string) =>
      nameSearch ? `AssetCondition?Search=${nameSearch}` : "AssetCondition",
    show: (id: any) => `AssetCondition/${id}`,
    post: "AssetCondition",
    put: "AssetCondition",
    delete: (id: string) => `AssetCondition/${id}`,
  },
  fixedAssets: {
    get: ({ query = "" }) => `FixedAsset${query && `?${query}`}`,
    show: (id: any) => `FixedAsset/GetFixedAsset/${id}`,
    post: "FixedAsset/PostFixedAsset",
    put: "FixedAsset/Update",
    delete: (id: string) => `FixedAsset/Delete?id=${id}`,
  },
  dischargeFixedAssets: {
    get: ({ query = "" }) => `AssetDischarge/GetAssetDischarge${query && `?${query}`}`,
    show: (id: any) => `AssetDischarge/GetAssetDischarge/${id}`,
    post: "AssetDischarge",
    put: "AssetDischarge/UpdateAssetDischarge",
    delete: (id: string) => `AssetDischarge/${id}`,
    approved: `AssetDischarge/Approve`,
  },
  maintenance: {
    get: (nameSearch: string) =>
      `Maintenance/GetMaintenance?search=${nameSearch}`,
    show: (id: any) => `Maintenance/GetMaintenance/${id}`,
    post: "Maintenance/PostMaintenance",
    put: "Maintenance/UpdateMaintenance",
    delete:(id : string) => `Maintenance/DeleteMaintenance?id=${id}`,
  },
  requestMaintenance: {
    get: ({ query = "" }) => `RequestMaintenance${query && `${query}`}`,
    show: (id: any) => `RequestMaintenance/${id}`,
    post: "RequestMaintenance",
    put: "RequestMaintenance",
    delete: (id: string) => `RequestMaintenance/${id}`,
    approved: `RequestMaintenance/Approve`,
  },
  laboratoryMaintenanceRequest: {
    get: ({ query = "" }) => `LaboratoryMaintenanceRequest?${query && `${query}`}`,
    show: (id: any) => `LaboratoryMaintenanceRequest/${id}`,
    showNumber: (number: any) => `LaboratoryMaintenanceRequest/Number/${number}`,
    post: "LaboratoryMaintenanceRequest",
    put: "LaboratoryMaintenanceRequest",
    delete: (id: string) => `LaboratoryMaintenanceRequest/${id}`,
    maintenance: `LaboratoryMaintenanceRequest/RequiredMaintenance`,
  },
  technicalSupport: {
    get: ({ query = "" }) => `TechnicalSupport?${query && `${query}`}`,
    show: (id: any) => `TechnicalSupport/${id}`,
    showNumber: (number: any) => `TechnicalSupport/Number/${number}`,
    post: "TechnicalSupport",
    put: "TechnicalSupport",
    delete: (id: string) => `TechnicalSupport/${id}`,
  },
  correspondenceConfig: {
    get: ({ query = "" }) => `CorrespondenceConfig${query && `?${query}`}`,
    getOrigin: "CorrespondenceConfig/GetByCorrespondenceType/origen",
    getDestiny: "CorrespondenceConfig/GetByCorrespondenceType/destino",
    show: (id: any) => `CorrespondenceConfig/Get/${id}`,
    post: "CorrespondenceConfig/Post",
    put: "CorrespondenceConfig/Update",
  },
  position: {
    get: ({ query = "" }) => `Position${query && `?${query}`}`,
    show: (id: any) => `Position/GetByIdPosition/${id}`,
    post: "Position/PostPosition",
    put: (id: any) => `Position/UpdatePosition/${id}`,
  },
  typology: {
    get: (nameSearch: string) => `Typology?search=${nameSearch}`,
    show: (id: any) => `Typology/GetTypology/${id}`,
    post: "Typology/PostTypology",
    put: "Typology/UpdateTypology",
  },
  certificate: {
    update: "Certificate/Update",
    generate: "Certificate/Generate",
    getall: "Certificate/GetAll",
    hascertificate: (announcementId: string)=>`Certificate/HasCertificate/${announcementId}`,
    preview: (announcementId: string)=>`Certificate/Preview/${announcementId}`,
    getAnnouncementGraduated: (email: string)=>`Certificate/GetAnnouncementGraduated/${email}`,
  },
  letterSigned: {
    get: "LetterSigned/Get",
    getCourses:(email: string)=> `LetterSigned/GetCourses/${email}`,
    getByStudentAnnouncementRequestId: (announcementRequestId: string) => `LetterSigned/GetByStudentAnnouncementRequestId/${announcementRequestId}`,
    post: "LetterSigned/Update",
    preview: "LetterSigned/Preview",
  },
  book: {
    get: (nameSearch: string) => `Book?search=${nameSearch}`,
    getByIdLanguage: (id: string) => `Book/GetBookByLanguage?languagueId=${id}`,
    show: (id: any) => `Book/GetBook/${id}`,
    post: "Book/Post",
    put: "Book/UpdateBook",
  },
  article: {
    get: ({ query = "" }) => `Articles/GetArticles${query && `?${query}`}`,
    show: (id: any) => `Articles/GetArticles/${id}`,
    post: "Articles/PostArticles",
    put: "Articles/UpdateArticles",
  },
  perDiemAndTravel: {
    get: (nameSearch: string) => `TravelAmounts?search=${nameSearch}`,
    show: (id: any) => `TravelAmounts/GetTravelAmount/${id}`,
    post: "TravelAmounts/PostTravelAmounts",
    put: "TravelAmounts/UpdateTravelAmounts",
  },
  correspondence: {
    get: "Correspondence/GetCorrespondence",
    getByType: ({ query = "" }) =>
      `Correspondence/GetCorrespondence${query && `?${query}`}`,
    show: (id: any) => `Correspondence/GetCorrespondence/${id}`,
    post: "Correspondence/PostCorrespondence",
    put: "Correspondence/UpdateCorrespondence",
  },
  fuel: {
    get: ({ query = "" }) => `AssignmentTicketFuel${query && `?${query}`}`,
    show: (id: any) => `AssignmentTicketFuel/GetAssignmentTicket/${id}`,
    post: "AssignmentTicketFuel/PostAssignmentTicket",
    put: (id: any) => `AssignmentTicketFuel/Update/${id}`,
  },
  employee: {
    get: ({ query = "" }) => `Employees/GetAllEmployee${query && `?${query}`}`,
    allTeachers: "Employees/GetAllTeachers",
    getInfo: (email: any) => `Employees/GetEmployeesInformation?email=${email}`,
    show: (id: any) => `Employees/GetEmployees/${id}`,
    post: "Employees/PostEmployee",
    put: (id: string) => `Employees/UpdateEmployees?id=${id}`,

    postUser: "Employees/MakeUserEmployee",

    postCondition: "EmployeeCondicion/PostEmployeeCondicion",

    postAcademic: "EmployeeAcademicInformation/PostAcademicInformation",
    getAcademicByIdEmployee: (id: string) =>
      `EmployeeAcademicInformation/GetByEmployee/${id}`,
    putAcademic: (id: string) =>
      `EmployeeAcademicInformation/UpdateAcademicInformation/${id}`,

    getLaborDataById: (id: string) => `EmployeeLaborData/GetByEmployee/${id}`,
    putLaborData: (id: string) => `EmployeeLaborData/UpdateLaborData/${id}`,
    postLaborData: "EmployeeLaborData/PostLaborData",

    getLaborDataPreviousById: (id: string) =>
      `EmployeeLaborDatePrevious/GetByEmployee/${id}`,
    putLaborDataPrevious: `EmployeeLaborDatePrevious/UpdateLaborDatePrevious`,
    postLaborDataPrevious: "EmployeeLaborDatePrevious/PostLaborDatePrevious",

    getDocsById: (id: string) => `Document/GetDocuments/${id}`,
    postDocs: "Document/PostDocuments?typeUser=empleado",
    putDocs: "Document/UpdateDocuments",
  },
  transfer: {
    get: "JobTransferRequest",
    show: (id: any) => `JobTransferRequest/GetJobTransferRequest/${id}`,
    post: "JobTransferRequest/PostJobTransferRequest",
    put: (id: any) => `JobTransferRequest/UpdateJobTransferRequest/${id}`,
  },
  resignation: {
    get: "LaborResignation",
    show: (id: any) => `LaborResignation/GetLaborResignation/${id}`,
    post: "LaborResignation/PostLaborResignation",
    put: (id: any) => `LaborResignation/UpdateLaborResignation/${id}`,
  },
  emergencyContact: {
    post: "ApplicantContactEmergency/PostApplicantContactEmergency",
  },
  university: {
    get: ({ query = "" }) => `University${query && `?${query}`}`,
    show: (id: any) => `University/${id}`,
    post: "University",
    put: "University",
  },
  career: {
    get: ({ query = "" }) => `Career${query && `?${query}`}`,
    show: (id: any) => `Career/GetCarreer/${id}`,
    post: "Career/PostCareer",
    put: "Career/UpdateCareer",
  },
  center: {
    get: (nameSearch: string, announcements: string, institutionId: string = "") =>
      `Center?idProvinces=${nameSearch}&announcementsId=${announcements}${institutionId && `&institutionId=${institutionId}`}`,
    getTandas: (idDependence: string, idAnnouncement: string) =>
      `Center/GetTandas?dependencesId=${idDependence}&announcementsId=${idAnnouncement}`,
  },
  applicant: {
    getDashboard: (announcement: string, province: string) =>
      `Applicant/GetDashBoard?AnouncementId=${announcement}&ProvinceId=${province}`,
    get: (nameSearch: string) => `Applicant?search=${nameSearch}`,
    students: ({ query = "" }) => `Applicant/Students${query && `?${query}`}`,
    post: "Applicant",
    put: "Applicant",
    putVideo: (IdApplicant = "", URLVideo = "") =>
      `Applicant/UpdateVideo?IdApplicant=${IdApplicant}&URLVideo=${URLVideo}`,
    putRetreat: ({ query = "" }) => `Applicant/Retreat${query && `?${query}`}`,
    changeEmail: "Applicant/ChangeEmail",
    getAnnouncementId: (email: string) =>
      `Applicant/GetApplicantId?email=${email}`,
    getApplicantId: (id: string) => `Applicant/GetApplicant/${id}`,
    getInfoApplicant: (id: string) =>
      `Applicant/GetInformation?idApplicant=${id}`,
    putDocumentApplicant: "Applicant/UpdateDocument",
    getStatusAnnouncement: (announcementId: string, cedula: string) =>
      `Applicant/GetStatusAnnouncement?AnnouncementId=${announcementId}&Identification=${cedula}`,
  },
  applicantTeacher: {
    get: (nameSearch: string) => `ApplicantTeacher?search=${nameSearch}`,
    post: "ApplicantTeacher",
    put: "ApplicantTeacher/UpdateApplicantTeacher",
  },
  announcementRequest: {
    get: ({ query = "" }) =>
      `AnnouncementRequest/GetAll${query && `?${query}`}`,
    show: (id: any) =>
      `AnnouncementRequest/GetById?idAnnouncementRequest=${id}`,
    showByIdAnnouncement: (id: any) =>
      `AnnouncementRequest/GetByAnnouncement?idAnnouncement=${id}`,

    getByStatus: ({ query = "" }) =>
      `AnnouncementRequest/GetByStatus${query && `?${query}`}`,

    putShowStatusAll: (id = "") =>
      `AnnouncementRequest/SetShowStatusAll?announcementId=${id}`,
    postMassivelyDiscarded: "AnnouncementRequest/MassivelyDiscarded",
    postMassivelyDiscardedOverfilled:
      "AnnouncementRequest/MassivelyDiscardedOverfilled",

    putSelected: (id: string, comment: string, additionalData?: string) =>
      `AnnouncementRequest/SetSeleccionado?idAnnouncementRequest=${id}` +
      (comment ? `&comment=${encodeURIComponent(comment)}` : '') +
      (additionalData || ''),
  
    putNotSelected: (id: string, reason: string, comment: string, additionalData?: string) =>
      `AnnouncementRequest/SetNoSeleccionado?idAnnouncementRequest=${id}` +
      (reason ? `&reason=${encodeURIComponent(reason)}` : '') +
      (comment ? `&comment=${encodeURIComponent(comment)}` : '') +
      (additionalData || ''),
  
    putOverfilled: (id: string, comment: string, additionalData?: string) =>
      `AnnouncementRequest/SetSobrellenado?idAnnouncementRequest=${id}` +
      (comment ? `&comment=${encodeURIComponent(comment)}` : '') +
      (additionalData || ''),
  
    putInProcess: (id: string, comment: string, additionalData?: string) =>
      `AnnouncementRequest/SetEnProgreso?idAnnouncementRequest=${id}` +
      (comment ? `&comment=${encodeURIComponent(comment)}` : '') +
      (additionalData || ''),
  },
  rol: {
    get: "Setup/GetAllRoles",
  },
  assignTeachers: {
    get: ({ query = "" }) =>
      `AssignmentDependenceTeachers${query && `?${query}`}`,
    show: (id: any) =>
      `AssignmentDependenceTeachers/GetAssignmentDependenceTeachers/${id}`,
    post: "AssignmentDependenceTeachers/PostAssignmentDependenceTeachers",
    put: "AssignmentDependenceTeachers/UpdateAssignmentDependenceTeachers",
    getTeacherTandaByDependences: (idDependence: string, idTeacher: string) =>
      `AssignTeachers/GetTeacherTandaByDependences?idDependence=${idDependence}&idTeacher=${idTeacher}`,
    delete: (id: string) =>
      `AssignmentDependenceTeachers/Delete?id=${id}`,
  },
  assignTechnical: {
    get: ({ query = "" }) =>
      `AssignmentCenterTechnician${query && `?${query}`}`,
    getEmployee: "Employees/GetEmployee?Rol=Soporte de Admisiones",
    show: (id: any) =>
      `AssignmentCenterTechnician/GetAssignmentCenterTechnicians/${id}`,
    post: "AssignmentCenterTechnician/PostAssignmentCenterTechnicians",
    put: "AssignmentCenterTechnician/UpdateAssignmentCenterTechnicians",
  },
  assignSupervisor: {
    get: (query: any) => `AssignmentCenterSupervisors${query && `?${query}`}`,
    getEmployee: "Employees/GetEmployee?Rol=Supervisor",
    show: (id: any) =>
      `AssignmentCenterSupervisors/GetAssignmentCenterSupervisors/${id}`,
    post: "AssignmentCenterSupervisors/PostAssignmentCenterSupervisors",
    put: "AssignmentCenterSupervisors/UpdateAssignmentCenterSupervisors",
    delete: (id: string) => `AssignmentCenterSupervisors/Delete?id=${id}`,
  },
  assignCoordinator: {
    get: ({ query = "" }) =>
      `AssignmentCenterCoordinator${query && `?${query}`}`,
    getEmployee: "Employees/GetEmployee?Rol=Coordinador",
    show: (id: any) =>
      `AssignmentCenterCoordinator/GetAssignmentCenterCoordinator/${id}`,
    post: "AssignmentCenterCoordinator/PostAssignmentCenterCoordinator",
    put: "AssignmentCenterCoordinator/UpdateAssignmentCenterCoordinators",
    getDependece:
      "AssignmentCenterCoordinator/ValidateCoordinatorAssignedCenters",
  },
  assignManager: {
    get: ({ query = "" }) =>
      `AssignmentCenterTeacherCharge${query && `?${query}`}`,
    getDependence: (idDependence: string, idAnnouncement: string) =>
      `AssignmentCenterTeacherCharge/GetAssignmentCenterTeacherByDependence?idDependece=${idDependence}&idAnnouncement=${idAnnouncement}`,
    show: (id: any) =>
      `AssignmentCenterTeacherCharge/GetAssignmentCenterTeacherCharge/${id}`,
    post: "AssignmentCenterTeacherCharge/PostAssignmentCenterTeacherCharge",
    put: "AssignmentCenterTeacherCharge/UpdateAssignmentCenterTeacherCharge",
    delete: (id: string) => `AssignmentCenterTeacherCharge/Delete?id=${id}`,
  },
  teacherClassroom: {
    get: ({ anouncement, Dependence, FullName }: any) =>
      `ClassroomTeacher?anouncement=${anouncement}&Dependence=${Dependence}&FullName=${FullName}`,
    show: (id: any) => `ClassroomTeacher/GetClassroomTeacher/${id}`,
    post: "ClassroomTeacher/PostClassroomTeacher",
    put: "ClassroomTeacher/UpdateClassroomTeacher",
  },
  training: {
    get: ({ query = "" }) => `Training${query && `?${query}`}`,
    show: (id: any) => `Training/GetTraining/${id}`,
    post: "Training/PostTraining",
    put: (id: string) => `Training/UpdateTraining/${id}`,
    delete: (id: string) => `Training/Delete?id=${id}`,
  },
  configurationQuestionSupervisor: {
    get: (search?: any) => `ConfigurationQuestionSupervisor?search=${search ?? ''}`,
    show: (id: any) =>
      `ConfigurationQuestionSupervisor/GetConfigurationQuestionSupervisor/${id}`,
    post: "ConfigurationQuestionSupervisor/PostConfigurationQuestionSupervisor",
    put: `ConfigurationQuestionSupervisor/UpdateConfigurationQuestionSupervisor`,
    delete: (id: string) => `ConfigurationQuestionSupervisor/Delete?id=${id}`,
  },
  configurationVisitSupervisor: {
    get: (query? : string) =>`ConfigurationVisitSupervisor${query && `?${query}`}`,
    show: (id: any) =>
      `ConfigurationVisitSupervisor/GetConfigurationVisitSupervisor/${id}`,
    post: "ConfigurationVisitSupervisor/PostConfigurationVisitSupervisor",
    put: `ConfigurationVisitSupervisor/UpdateConfigurationVisitSupervisor`,
    delete: (data: string) => `ConfigurationVisitSupervisor/Delete?id=${data}`,
  },
  configurationDetailSupervisor: {
    get: "ConfigurationDetailVisitSupervisor",
    show: (id: any) =>
      `ConfigurationDetailVisitSupervisor/GetConfigurationDetailVisitSupervisor/${id}`,
    post: "ConfigurationDetailVisitSupervisor/AddConfigurationDetailVisitSupervisor",
    put: `ConfigurationDetailVisitSupervisor/UpdateConfigurationDetailVisitSupervisor`,
  },
  trainingEvent: {
    get: ({ query = "" }) => `TrainingEvent/Get${query && `?${query}`}`,
    show: (id: any) => `TrainingEvent/GetTrainingEvent/${id}`,
    post: "TrainingEvent/PostTrainingEvent",
    put: (id: string) => `TrainingEvent/UpdateTrainingEvent/${id}`,
    delete: (id: string) => `TrainingEvent/Delete?id=${id}`,
  },
  configurationVisitSupervisorCenter: {
    get: "ConfigurationVisitSupervisorCenter",
    get2: ({ query = "" }) =>
      `ConfigurationVisitSupervisorCenter${query && `?${query}`}`,
    delete: (id: string) =>
      `ConfigurationVisitSupervisorCenter/Delete?id=${id}`,
  },
  announcementTeacher: {
    get: (query: string = "") => `AnnouncementTeacher/Get${query && `?${query}`}`,
    show: (id: any) => `AnnouncementTeacher/${id}`,
    update: () => 'AnnouncementTeacher',
    post: "AnnouncementTeacher",
    put: (id: string) => `AnnouncementTeacher/${id}`,
    getByStatus: ({ query = "" }) =>
      `AnnouncementTeacher/GetByStatus${query && `?${query}`}`,
    setByStatus: () =>
      `AnnouncementTeacher/SetStatus`,
    getTeacherAnnouncement: (query: string = "") => `ApplicantTeacher${query && `?${query}`}`,
    getTeacherAnnouncementById: (id: any) => `ApplicantTeacher/${id}`,
    teacherAnnouncementStatus: "ApplicantTeacher/Status",
  },
  narrative: {
    get: (nameSearch: string) => `Narrative/Get?Search=${nameSearch}`,
    getByIdLanguage: (id: string) => `Narrative/GetByLanguage?languageId=${id}`,
    show: (id: any) => `Narrative/Get/${id}`,
    post: "Narrative/Post",
    put: "Narrative/Update",
    delete: (id: string) => `Narrative/Delete?id=${id}`,
  },
  examCategory: {
    get: (query: string = "") => `ExamCategory/Get?search=${query}`,
    getByIdLanguage: (id: string) =>
      `ExamCategory/GetByLanguage?languageId=${id}`,
    getByIdNarrative: (id: string) =>
      `ExamCategory/GetByNarrative?narrativeId=${id}`,
    show: (id: any) => `ExamCategory/Get/${id}`,
    post: "ExamCategory",
    put: "ExamCategory",
    delete: (id: string) => `ExamCategory?id=${id}`,
  },
  examQuestion: {
    get: (query: string) => `ExamQuestion/Get?${query}`,
    getByLanguageId: (id: string) =>
      `ExamQuestion/GetAllExamQuestionByLanguague?languageId=${id}`,
    getByNarrativeId: (id: string) =>
      `ExamQuestion/GetAllExamQuestionByNarrative?narrativeId=${id}`,
    getByLevelId: (id: string) =>
      `ExamQuestion/GetAllExamQuestionByLevel?level=${id}`,
    getByTypeAnswerId: (id: string) =>
      `ExamQuestion/GetAllExamQuestionByTypeAnswer?typeAnswerId=${id}`,
    show: (id: string) => `ExamQuestion/GetGetArticles?idExamCategory=${id}`,
    post: "ExamQuestion/Post",
    put: "ExamQuestion/Update",
    delete: (id: string) => `ExamQuestion/Delete?id=${id}`,
  },
  exam: {
    get: (query: string) => `Exam/Get?${query}`,
    show: (id: string) => `Exam/GetById?id=${id}`,
    post: "Exam/Post",
    put: "Exam/Update",
    delete: (id: string) => `Exam/Delete?id=${id}`,
    getExamsCategory: (query: string) => `Exam/GetExamsCategory${query && `?${query}`}`,
  },
  examAssignment: {
    get: (query: string) => `ExamAssignment/Get?${query}`,
    getById: (id: string) => `ExamAssignment/GetById?id=${id}`,
    post: "ExamAssignment/Post",
    put: "ExamAssignment/Update",
    delete: (id: string) => `ExamAssignment/Delete?id=${id}`,
  },
  bankExam: {
    get: (query: string) => `Exam/GetFromBank${query && `?${query}`}`,
    getBankExamById: (id: string) => `Exam/GetById?id=${id}`,
    post: "Exam/AddCloneExam"
  },
  typeAnswer: {
    get: "TypeAnswer",
  },
  store: {
    get: "Store",
    getStore: ({ query = "" }) => `Store/GetStore${query && `?${query}`}`,
    show: (id: string) => `Store/GetById?id=${id}`,
    post: "Store/Post",
    put: "Store/Update",
    delete: (id: string) => `Store/Delete?id=${id}`,
  },
  booksInStore: {
    getBooksInStore: ({ query = "" }) =>
      `AssignBookToStore/GetBooksInStore${query && `?${query}`}`,
    getBooksInStoreByLanguageId: (languageId: string) =>
      `AssignBookToStore/GetBooksInStoreByLanguageId?languageId=${languageId}`,
    getBooksInStoreByTypologyId: (typologyId: string) =>
      `AssignBookToStore/GetBooksInStoreByTypologyId?typologyId=${typologyId}`,
    getBooksInStoreByBookId: (bookId: string) =>
      `AssignBookToStore/GetBooksInStoreByBookId?bookId=${bookId}`,
    getBooksInStoreByLevelId: (levelId: string) =>
      `AssignBookToStore/GetBooksInStoreByLevelId?levelId=${levelId}`,
  },
  assignBookToStore: {
    get: ({ query = "" }) => `AssignBookToStore${query && `?${query}`}`,
    show: (id: string) => `AssignBookToStore/GetById?id=${id}`,
    post: "AssignBookToStore/Post",
    put: "AssignBookToStore/Update",
    delete: (id: string) => `AssignBookToStore/Delete?id=${id}`,
    downloadStore: "AssignBookToStore/Download",
  },
  bookRequest: {
    get: ({ query = "" }) =>  `BookRequest${query && `?${query}`}`,
    show: (id: string) => `BookRequest/GetById?id=${id}`,
    post: "BookRequest/Post",
    put: "BookRequest/Update",
    delete: (id: string) => `BookRequest/Delete?id=${id}`,
    approve: "BookRequest/Approve",
  },
  assigningBooksStudents: {
    get: (institutionId: string, dependenceId: string) =>  `AssignBookToStore/GetInstitutionsBooks?institutionId=${institutionId}&dependenceId=${dependenceId}`,
    post: "AssignBookToStore/AssingBooksStudents",
    delete: "AssignBooksStudents/Delete",
    getBooksByStudentId: (studentId: string, institutionId: string, dependenceId: string) => `AssignBookToStore/AssignBooksStudents/GetBooksByStudent?studentId=${studentId}&institutionId=${institutionId}&dependenceId=${dependenceId}`,
    getBooksByStudent: ({ query = "" }) => `AssignBookToStore/AssignBooksStudents/GetBooksByStudent${query && `?${query}`}`,
  },
  studentAssignment: {
    get: ({ query = "" }) => `StudentAssignment${query && `?${query}`}`,
    getWithStudent: ({ query = "" }) =>
      `StudentAssignment/GetClassroomWithStudent${query && `?${query}`}`,
    getById: ({ id = "", query = "" }) =>
      `StudentAssignment/GetStudenAssignment/${id}${query && `?${query}`}`,
    post: "StudentAssignment/PostStudenAssignment",
    put: ({ id = "" }) => `StudentAssignment/UpdateStudenAssignment/${id}`,
    delete: ({ id = "" }) => `StudentAssignment/Delete?id=${id}`,
  },
  config: {
    postReportHeaderImage: "ReportImageHeader/Add",
    getReportHeaderImage:"ReportImageHeader/Get",

    postBackgroundImage: "Configurations/CreateBackgroundImagen",
    getBackgroundImage: ({ query = "" }) =>
      `Configurations/GetBackgroundImagen${query && `?${query}`}`,

    postManualTeacher: "Configurations/CreateManualTeacher",
    getManualTeacher: ({ query = "" }) =>
      `Configurations/GetManualTeacher${query && `?${query}`}`,

    postManualApplicant: "Configurations/CreateManualApplicant",
    getManualApplicant: ({ query = "" }) =>
      `Configurations/GetManualApplicant${query && `?${query}`}`,

    postManualCoordinator: "Configurations/CreateManualCoordinator",
    getManualCoordinator: ({ query = "" }) =>
      `Configurations/GetManualCoordinator${query && `?${query}`}`,
  },
  qualifications: {
    url: ({ query = "" }) => `Qualifications${query && `?${query}`}`,
    centerLevel: ({ query = "" }) =>
      `Qualifications/GetPreQualifications${query && `?${query}`}`,
    student: ({ query = "" }) =>
      `Qualifications/PreQualifications${query && `?${query}`}`,
    getStudent: (id: string) =>
      `Qualifications/GeApplicantQualifications?applicationId=${id}`,
  },
  studentTransferRequest: {
    get: ({ query = "" }) =>
      `StudentTransferRequests/GetStudentTransferRequests${query && `?${query}`}`,
    getById: (id: string) =>
      `StudentTransferRequests/${id}`,
    putApproval:
      "StudentTransferRequests/UpdateApprovedStudentTransferRequests",
    post: "StudentTransferRequests",
    postManualTransfer: "StudentTransferRequests/ManualTransfer",
    put: "StudentTransferRequests/UpdateStudentTransferRequests",
    delete: (id: string) => `StudentTransferRequests/Delete?id=${id}`,
  },
  notification: {
    get: (request: NotificationRequest) => `notification/all/${request.id}/${request.page}/${request.quantity}`,
    delete: (id: string) => `notification/delete/${id}`,
    read: 'notification/read'
  },
  studentRetireSolicitude: {
    getAll: ({ query = "" }) =>
      `RequestStudentWithdrawals/ByFilter${query && `?${query}`}`,
    getById: (id?: string ) => `RequestStudentWithdrawals/${id}`,
    post: 'RequestStudentWithdrawals',
    postManualWithdrawal: 'RequestStudentWithdrawals/ManualWithdrawal',
    put: 'RequestStudentWithdrawals/Update',
    putApproveRequest: 'RequestStudentWithdrawals/ApproveRequest',
  },
  calendarEvent: {
    getCalendarEvents: ({ query = "" }) => `/CalendarEvent${query && `?${query}`}`,
    getCalendarEventsById: (id: string) => `/CalendarEvent/${id}`,
    post: "CalendarEvent/PostCalendarEvent",
    put: "CalendarEvent/UpdateCalendarEvent"
  },
};
